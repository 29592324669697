<template>
    <div class="p-contract-customer" v-loading="!ready">
        <div class="hd">
            <div class="page-title">合同管理</div>
        </div>
        <div class="bd" v-loading="isLoading">
            <!-- <form-fields :fields="fields" :prop="fields"></form-fields> -->
            <div class="topbar">
                <div class="add">
                    <el-button
                        type="primary"
                        icon="el-icon-circle-plus-outline"
                        @click="onAddButtonclicked"
                        >新增</el-button
                    >
                </div>
                <div class="search">
                    <el-input
                        placeholder="搜索"
                        v-model="search.keyword"
                        clearable
                    ></el-input>
                </div>
            </div>
            <div class="add-wrap" v-if="isFormShowing">
                <el-form
                    :model="form"
                    :rules="rules"
                    ref="form"
                    label-width="100px"
                >
                    <el-form-item label="客户" prop="customerId">
                        <el-select
                            v-model="form.customerId"
                            :clearable="true"
                            :filterable="true"
                        >
                            <el-option
                                v-for="item of customerSelectList"
                                :key="item.value"
                                :value="item.value"
                                :label="item.label"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="销售" prop="salesId">
                        <el-select
                            v-model="form.salesId"
                            :clearable="true"
                            :filterable="true"
                        >
                            <el-option
                                v-for="item of accountSelectList"
                                :key="item.value"
                                :value="item.value"
                                :label="item.label"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="合同号" prop="number">
                        <el-input v-model="form.number"></el-input>
                    </el-form-item>
                    <el-form-item label="签订日期">
                        <el-date-picker
                            v-model="form.signDate"
                            type="date"
                            placeholder="请选择日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="结束日期">
                        <el-date-picker
                            v-model="form.endDate"
                            type="date"
                            placeholder="请选择日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="add">提交</el-button>
                        <el-button @click="onCancelAdding">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="list" v-show="!isFormShowing">
                <el-table :data="contractList">
                    <el-table-column label="ID" prop="id"></el-table-column>
                    <el-table-column
                        label="合同号"
                        prop="number"
                    ></el-table-column>
                    <el-table-column
                        label="客户名称"
                        prop="customerName"
                    ></el-table-column>
                    <el-table-column
                        label="销售"
                        prop="salesName"
                    ></el-table-column>
                    <el-table-column
                        label="签订日期"
                        prop="signDate"
                    ></el-table-column>
                    <el-table-column
                        label="结束日期"
                        prop="endDate"
                    ></el-table-column>
                    <!-- <el-table-column label="项目">
						<template>
						</template>
					</el-table-column> -->
                    <!-- <el-table-column label="操作">
						<template scope="scope">
							<el-button type="primary" size="mini" plain @click="onEdit(scope.row)">编辑</el-button>
						</template>
					</el-table-column> -->
                </el-table>
            </div>
        </div>
        <div class="ft"></div>
    </div>
</template>

<style lang="less">
.p-contract-customer {
    .bd {
        .topbar {
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;

            & > div {
                padding-right: 10px;
            }
        }

        .add-wrap {
            padding-top: 10px;
            border: 1px solid #409eff;
            border-radius: 10px;
        }
    }
}
</style>

<script>
// import FormFields from '../components/form-fields.vue'
import moment from "moment";

export default {
    components: {},
    data() {
        let that = this;
        return {
            ready: false,
            isLoading: false,
            search: {
                keyword: "",
            },
            form: {
                customerId: 0,
                salesId: 0,
                number: "",
                signDate: "",
                endDate: "",
            },
            rules: {
                customerId: [
                    { required: true, message: "请选择客户", trigger: "blur" },
                    {
                        validator: function (rule, value, callback) {
                            if (value > 0) {
                                callback();
                            } else {
                                callback(new Error("请选择客户"));
                            }
                        },
                        trigger: "change",
                    },
                ],
                salesId: [
                    { required: true, message: "请选择销售", trigger: "blur" },
                    {
                        validator: function (rule, value, callback) {
                            if (value > 0) {
                                callback();
                            } else {
                                callback(new Error("请选择销售"));
                            }
                        },
                        trigger: "change",
                    },
                ],
                number: [
                    {
                        required: true,
                        message: "请填写合同号",
                        trigger: "blur",
                    },
                    {
                        min: 8,
                        max: 10,
                        message: "请输入正确的合同号",
                        trigger: "blur",
                    },
                    {
                        validator: function (rule, value, callback) {
                            let existed = false;
                            for (let i = 0; i < that.contracts.length; i++) {
                                let contract = that.contracts[i];
                                if (contract.number === value) {
                                    existed = true;
                                    break;
                                }
                            }
                            if (existed) {
                                callback(new Error("已存在相同的合同号"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
            },
            isFormShowing: false,
            customers: [],
            contracts: [],
            accounts: [],
        };
    },
    computed: {
        customerSelectList() {
            let result = [{ value: 0, label: "（请选择）" }];
            for (let i = 0; i < this.customers.length; i++) {
                let customer = this.customers[i];
                result.push({ value: customer.id, label: customer.name });
            }
            return result;
        },
        accountSelectList() {
            let result = [{ value: 0, label: "（请选择）" }];
            for (let i = 0; i < this.accounts.length; i++) {
                let account = this.accounts[i];
                result.push({ value: account.id, label: account.display_name });
            }
            return result;
        },
        contractList() {
            let result = [];
            for (let i = 0; i < this.contracts.length; i++) {
                let contract = this.contracts[i];
                if (
                    this.search.keyword &&
                    contract.number
                        .toLowerCase()
                        .indexOf(this.search.keyword.toLowerCase()) < 0 &&
                    contract.customerName
                        .toLowerCase()
                        .indexOf(this.search.keyword.toLowerCase()) < 0
                ) {
                    continue;
                }
                contract.signDate = contract.sign_date
                    ? moment(contract.sign_date).format("YYYY-MM-DD")
                    : "";
                contract.endDate = contract.end_date
                    ? moment(contract.end_date).format("YYYY-MM-DD")
                    : "";
                result.push(contract);
            }
            return result;
        },
    },
    mounted() {
        let that = this;
        // moment.locale('zh_CN')
        that.getContractList()
            .then(function () {
                return that.getCustomerList();
            })
            .then(() => {
                return that.getAccountList();
            })
            .then(() => {
                that.ready = true;
            })
            .catch((e) => {
                console.log(e);
            });
    },
    methods: {
        getContractList() {
            let that = this;
            return new Promise(function (resolve, reject) {
                if (that.isLoading) {
                    reject(new Error("Another request is processing"));
                    return;
                }
                that.isLoading = true;
                that.$ajax("/contract/list", {})
                    .done(function (ret) {
                        that.isLoading = false;
                        that.contracts = ret;
                        resolve();
                    })
                    .fail(function (e) {
                        that.isLoading = false;
                        reject(e);
                    })
                    .always(function () {});
            });
        },
        getCustomerList() {
            let that = this;
            return new Promise((resolve, reject) => {
                if (that.isLoading) {
                    reject(new Error("Another request is processing"));
                    return;
                }
                that.isLoading = true;
                that.$ajax("/customer/list", {})
                    .done(function (ret) {
                        that.isLoading = false;
                        that.customers = ret;
                        resolve();
                    })
                    .fail(function (e) {
                        that.isLoading = false;
                        reject(e);
                    })
                    .always(function () {});
            });
        },
        getAccountList() {
            let that = this;
            return new Promise((resolve, reject) => {
                if (that.isLoading) {
                    reject(new Error("Another request is processing"));
                    return;
                }
                that.isLoading = true;
                that.$ajax("/account/get-list", {})
                    .done(function (ret) {
                        that.isLoading = false;
                        that.accounts = ret;
                        resolve();
                    })
                    .fail(function (e) {
                        that.isLoading = false;
                        reject(e);
                    })
                    .always(function () {});
            });
        },
        onAddButtonclicked() {
            this.isFormShowing = true;
        },
        onCancelAdding() {
            this.isFormShowing = false;
        },
        add() {
            let that = this;

            that.$refs["form"].validate((valid) => {
                if (!valid) {
                    return;
                }

                if (that.isLoading) {
                    return;
                }

                console.log(that.form.signDate);
                console.log(typeof that.form.signDate);

                let param = {
                    customerId: that.form.customerId,
                    salesId: that.form.salesId,
                    number: that.form.number,
                    signDate: that.form.signDate
                        ? moment(that.form.signDate).format("YYYY-MM-DD")
                        : "",
                    endDate: that.form.endDate
                        ? moment(that.form.endDate).format("YYYY-MM-DD")
                        : "",
                };

                that.isLoading = true;
                that.$ajax("/contract/add", param)
                    .done(function () {
                        that.isLoading = false;
                        that.isFormShowing = false;
                        that.getContractList();
                    })
                    .fail(function () {
                        that.isLoading = false;
                    })
                    .always(function () {});
            });
        },
        onEdit(customer) {
            let that = this;
            if (that.isLoading) {
                return;
            }
            let existed = false;
            that.$prompt("请输入新的客户名", "编辑", {
                inputValue: customer.name,
            }).then((r) => {
                let value = r.value.replace(/^\s+|\s+$/g, "");
                if (value === customer.name) {
                    return;
                }
                for (let i = 0; i < that.customers.length; i++) {
                    let item = that.customers[i];
                    if (item.id !== customer.id && item.name === value) {
                        existed = true;
                        break;
                    }
                }
                if (existed) {
                    that.$alert("已存在同名客户");
                } else {
                    that.isLoading = true;
                    that.$ajax("/contract/update", {
                        id: customer.id,
                        name: value,
                    })
                        .done(function () {
                            that.isLoading = false;
                            that.getContractList();
                        })
                        .fail(function () {
                            that.isLoading = false;
                        })
                        .always(function () {});
                }
            });
        },
    },
    filters: {},
};
</script>
